<template>
  <div class="d-flex">
    <el-dropdown
        @command="newIssue"
        class="d-flex align-self-center"
        placement="bottom-start"
        trigger="click"
    >
      <el-button
          class="px-2"
          size="mini"
      >
        <font-awesome-icon icon="plus" />
        {{ $t('system.issue') }}
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
            :command="tracker.id"
            :key="tracker.id"
            v-for="tracker in trackers"
        >
          {{ $t(tracker.translation) }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'CreateTrackerButton',
  computed: {
    ...mapState('trackers', ['trackers']),
  },
  methods: {
    newIssue(trackerId) {
      this.$store.commit('modal/OPEN_CREATE_MODAL', {id: trackerId, translation: 'issue'});
    },
  },
};
</script>

<style scoped>

</style>
